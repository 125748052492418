import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import "./ModalConfirmAviso.scss";

interface ModalConfirmAvisoProps {
  open: boolean;
  data: any;
}

const ModalConfirmAviso = ({ open, data }: ModalConfirmAvisoProps) => {
  const navigate = useNavigate()

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-confirm-aviso-success">
        <div className="header">
          <h5 className="text-center">
            Aviso generado correctamente
          </h5>
        </div>
        <div className="body">
          <div className="text-center ph-30">La id del aviso generado es el siguiente:</div>
          <div className="text-center ph-30">{data.draft_id}</div>
          <div className="button-container">
            <EsfirusButtonNative
              click={() => navigate("/borradores-avisos")}
              label="Ir al listado de borradores"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmAviso