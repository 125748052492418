import { useNavigate } from "react-router-dom";
import { useSelector } from "@services/hooks/redux-hooks";
import moment from "moment";
import EsfirusTable from "@components/ui/Table/EsfirusTable"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";
import { findUserByCompanyAndEmployeeIds } from "@helpers/utils";
import { NoticeDraft } from "@models/notices";
import { BORRADORES_AVISOS_STATUS_TYPES_LABELS } from "@screens/Avisos/constants";
import { useState } from "react";
import EsfirusButton from "@components/ui/Button/EsfirusButton";
import { avisosService } from "@services/avisos";
import "./ListadoAvisosBorradoresTable.scss"

interface ListadoAvisosTableProps {
  borradoresAvisos: NoticeDraft[]
  pagination: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination: HandleChangePaginationType
  reloadBorradoresAvisos: () => Promise<void>
}

const ListadoAvisosBorradoresTable = ({ borradoresAvisos, pagination, totalItems, handleChangePagination, reloadBorradoresAvisos }: ListadoAvisosTableProps) => {
  const navigate = useNavigate()
  const empresas = useSelector(state => state.empresas.list)
  const users = useSelector(state => state.users.list)
  const noticeTypes = useSelector((state) => state.notices.listTypes)

  // Estados de gestión de selección para validación.
  const [checkedRows, setCheckedRows] = useState<NoticeDraft[]>([])
  const [isValidating, setIsValidating] = useState<boolean>(false)

  const columns = [
    {
      name: "Empresa - Delegación",
      keymap: "_id",
      see: true,
      order: true,
      width: 200,
      notShowEdit: true,
      handleView: (row: any) => navigate(`/borradores-avisos/${row.unique_id}`),
    }, {
      name: "Fecha",
      keymap: "draft_date",
      parse: (v: any) => moment(v).format("DD-MM-YYYY"),
      order: true,
      width: 100
    }, {
      name: "Hora",
      keymap: "draft_time",
      parse: (v: any) => moment.utc(v, "HHmm").format("HH:mm:ss"),
      order: true,
      width: 50
    }, {
      name: "Cliente",
      keymap: "customer_or_potencial_id",
      order: true,
      width: 50
    }, {
      name: "Empleado origen",
      keymap: "origin_worker_name",
      order: true,
      width: 100
    }, {
      name: "Empleado destino",
      keymap: "target_worker_name",
      order: true,
      width: 100
    }, {
      name: "Empleado responsable",
      keymap: "worker_id_name",
      order: true,
      width: 100
    }, {
      name: "Estado",
      keymap: "draft_status_name",
      order: true,
      width: 100
    }, {
      name: "Tipo",
      keymap: "notice_type_name",
      order: true,
      width: 100
    }, {
      name: "Descripción",
      keymap: "notice_description",
      order: true,
      width: 150,
      tooltipProperty: "description_tooltip"
    }
  ]

  const parseRows = (rows: NoticeDraft[] = []) => {
    return rows.map(row => {
      const empresaName = empresas.find(empresa => empresa.Empresa == row.company_id)?.Nombre ?? "-"
      const empleadoOrigenName = findUserByCompanyAndEmployeeIds(users, row.company_id, row.origin_worker)
      const empleadoDestinoName = findUserByCompanyAndEmployeeIds(users, row.company_id, row.target_worker)
      const empleadoResponsableName = findUserByCompanyAndEmployeeIds(users, row.company_id, row.worker_id)
      const estadoName = BORRADORES_AVISOS_STATUS_TYPES_LABELS[row.draft_status] ?? "-"
      const tipoName = noticeTypes.find(tipo => tipo.notification_type == row.notice_type)?.description ?? "-"

      return ({
        _id: `${empresaName} - ${row.branch_id ?? ""}`,
        origin_worker_name: `${empleadoOrigenName?.nombre ?? "-"} ${empleadoOrigenName?.apellidos ?? "-"}`,
        target_worker_name: `${empleadoDestinoName?.nombre ?? "-"} ${empleadoDestinoName?.apellidos ?? "-"}`,
        worker_id_name: `${empleadoResponsableName?.nombre ?? "-"} ${empleadoResponsableName?.apellidos ?? "-"}`,
        draft_status_name: estadoName,
        notice_type_name: tipoName,
        description_tooltip: `Descripción: ${row.notice_description || ""}\nComentarios: ${row.comments || ""}`,
        ...row
      })
    })
  }

  const handleCheckRow = (data: { row: NoticeDraft }) => {
    setCheckedRows(prev => {
      const rowIncludeInChecked = prev.map(c => c.unique_id).includes(data.row.unique_id)
      if (rowIncludeInChecked) {
        return prev.filter(c => c.unique_id !== data.row.unique_id)
      } else {
        return [...prev, data.row]
      }
    })
  }

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setCheckedRows(borradoresAvisos)
    } else {
      setCheckedRows([])
    }
  }

  const validateAvisos = async (rows: NoticeDraft[]) => {
    setIsValidating(true)

    try {
      const promises = rows.map(notice => avisosService.validateBorradorAviso({ id: notice.unique_id }))
      await Promise.allSettled(promises)
      await reloadBorradoresAvisos()
    } catch (error) {
    } finally {
      setIsValidating(false)
    }
  }

  return (
    <div>
      <div className="avisos-validate-button-wrapper">
        <EsfirusButton
          className="new-parte-save-button"
          color="secondary"
          onClick={async () => await validateAvisos(checkedRows)}
          disabled={!checkedRows.length || isValidating}
        >
          {isValidating && <span style={{ height: 12, margin: 0 }} className="loader"></span>}
          Traspasar seleccionados
        </EsfirusButton>
      </div>
      <EsfirusTable
        columns={columns}
        rows={parseRows(borradoresAvisos)}
        rowsCount={totalItems}
        pagination={pagination}
        withPagination={true}
        handleChangePagination={handleChangePagination}
        handleCheckRow={handleCheckRow}
        handleCheckAll={handleCheckAll}
        checkedRows={checkedRows}
      />
    </div>
  )
}

export default ListadoAvisosBorradoresTable