import axios from "axios"
import { apiConfig } from "./config"
import { Configuration } from "./swagger/configuration"
import { CustomerAddress } from "@models/customer-address"
import { APIDataResponse } from "@models/api-models"

interface CustomerAddressesListResponse {
  page_entries: CustomerAddress[]
  total_entries: number
}

class ErpService {
  private accessToken = ""
  private basePath = ""

  constructor(_apiConfig: Configuration) {
    this.accessToken = (_apiConfig.accessToken || '') as string
    this.basePath = (_apiConfig.basePath || '') as string
  }

  async getSalesPricingArray(items = [], obra = {}) {
    try {
      const response = await axios({
        baseURL: this.basePath + '/erp/sales-pricing-array',
        headers: { Authorization: `Bearer ${localStorage.getItem("ef-token") as string}` },
        data: { items, obra },
        method: 'put'
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getSalesPricing(item = {}, obra = {}, quantity = 1, linea = {}) {
    try {
      const response = await axios({
        baseURL: this.basePath + '/erp/sales-pricing',
        headers: { Authorization: `Bearer ${localStorage.getItem("ef-token") as string}` },
        data: { item, obra, quantity, linea },
        method: 'put'
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getCustomerAddresses(customerId: number | string): Promise<APIDataResponse<CustomerAddressesListResponse>> {
    try {
      const response = await axios({
        baseURL: this.basePath + '/erp/customer-addresses',
        headers: { Authorization: `Bearer ${localStorage.getItem("ef-token") as string}` },
        params: { customerId },
        method: 'get'
      })

      return response.data
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }
}

export const erpService = new ErpService(apiConfig)
