import Grid from "@mui/material/Grid";
import textoIcon from "@assets/svgs/lineasTexto.svg";
import manoDeObraIcon from "@assets/svgs/lineasManoDeObra.svg";
import { EsfirusInputTextNative } from "@ui/InputTextNative/EsfirusInputTextNative";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import EsfirusButton from "@components/ui/Button/EsfirusButton";

import EsfirusSelectCustom from "@components/ui/SelectCustom/EsfirusSelectCustom";

import { useEffect, useState, useContext, useRef } from "react";
import { ConfigContext } from './context/Config.context'
import { useNavigate, useParams } from 'react-router-dom';

import { getRequest, putRequest } from '../../utils/requests'
import { textos, types } from './helpers';

import ModalConfirm from "./ModalConfirm/ModalConfirm";

import "./Config.scss"
import CacheInfo from "./CacheInfo";

const Config = (props) => {
    const history = useNavigate();

    const { state, setState } = useContext(ConfigContext)

    const [cache, setCache] = useState({})
    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [noticeStatuses, setNoticeStatuses] = useState([]);
    const [noticePriorities, setNoticePriorities] = useState([]);
    let hiddenFields = useRef({});

    //TODO: revisar esto cuando actualicemos el n-backoffice
    const params = useParams()

    const [openConfirmModal, setOpenConfirmModal] = useState();
    const [msg, setMsg] = useState('');

    // Loadings de los botones.
    const [loadingReenvio, setLoadingReenvio] = useState(false)
    const [loadingRefresh, setLoadingRefresh] = useState(false)

    // Efecto para resetear mensaje de modal.
    useEffect(() => {
        if (!openConfirmModal) setMsg("")
    }, [openConfirmModal])

    // Efecto para guardar los notices a partir de la recarga de caché.
    useEffect(() => {
        if (cache.notices_statuses && cache.notices_statuses !== "loading") setNoticeStatuses(cache.notices_statuses)
        if (cache.notices_priorities && cache.notices_priorities !== "loading") setNoticePriorities(cache.notices_priorities)
    }, [cache])

    const {
        id
    } = params; //props?.match?.params;


    const handleChange = (e, group, atribute) => {

        let updatedValue = e;
        if (updatedValue === "true" || updatedValue === "false") {
            updatedValue = JSON.parse(updatedValue);
        }


        let newState = {
            ...state,
            [id]: {
                ...state[id],
                [group]: {
                    ...state[id]?.[group],
                    [atribute]: updatedValue
                }
            }
        }
        if (atribute == "partesConfirmar") {
            hiddenFields.current.reportCerrarDias = !updatedValue
            newState[id]["report"]["reportCerrarDias"] = updatedValue;
        }

        const isConexionByEndpoint = newState[id]?.ftp_client?.ftp_method && newState[id]?.ftp_client?.ftp_method == "endpoint"
        hiddenFields.current.host = isConexionByEndpoint
        hiddenFields.current.password = isConexionByEndpoint
        hiddenFields.current.port = isConexionByEndpoint
        hiddenFields.current.username = isConexionByEndpoint

        setState(newState);
    }

    const actualizarConfig = async () => {
        const dataUpdate = {
            id,
            data: JSON.stringify(state[id])
        };
        const updData = await putRequest('config', dataUpdate);

        if (!updData?.ok) setMsg(updData?.message)
        setOpenConfirmModal(true);
    }


    const forceExport = async () => {

        const updData = await getRequest('forceExport');

        if (!updData?.ok) setMsg(updData?.message)
        setOpenConfirmModal(true);
    }

    const fetchData = async () => {
        const { data } = await getRequest(`configs/${id}?backoffice=1`)
        const dataJSON = JSON.parse(data.data);
        let newState = {
            ...state,
            [id]: {
                ...state[id],
                ...dataJSON
            }

        }

        if (typeof newState[id]?.cabeceraParte?.partesConfirmar !== "undefined" && !newState[id]?.cabeceraParte?.partesConfirmar) {
            hiddenFields.current.reportCerrarDias = true
            newState[id]["report"]["reportCerrarDias"] = false;
        }

        if (newState[id]?.ftp_client?.ftp_method === "endpoint") {
            hiddenFields.current.host = true
            hiddenFields.current.password = true
            hiddenFields.current.port = true
            hiddenFields.current.username = true
        }

        setState(newState);

        // Obtenemos datos del cacho
        const { data: dataCache } = await getRequest(`cache`);
        if (dataCache?.categorias) setCategorias(dataCache.categorias);
        if (dataCache?.tipos) setTipos(dataCache.tipos);
        setCache(dataCache)

    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleReintentarEnvíoPorEndpoint = async () => {
        setLoadingReenvio(true)

        const reenvio = await getRequest("config/resend_all_failed")
        if (reenvio?.data?.ok) {
            setMsg("El envío ha sido completado con éxito. Confirma los datos que te hemos enviado por correo.")
            setOpenConfirmModal(true)
        }

        setLoadingReenvio(false)
    }

    const handleRefreshCacheDataApi = async () => {
        setLoadingRefresh(true)

        const cacheLoading = Object.fromEntries(Object.entries(cache).map(([key]) => [key, "loading"]))
        setCache(cacheLoading)

        try {
            await getRequest('config/refresh_data')

            // Refrescamos datos locales de caché
            const { data: dataCache } = await getRequest(`cache`);
            if (dataCache?.categorias) setCategorias(dataCache.categorias)
            if (dataCache?.tipos) setTipos(dataCache.tipos)
            setCache(dataCache)
        } catch (error) {
            alert("Ha ocurrido un problema y no se ha podido refrescar los datos del servidor.")
        } finally {
            setLoadingRefresh(false)
        }
    }

    const renderAtribute = (keyName, _keyName) => {
        const _types = types?.find(e => e.property === _keyName);
        switch (_types?.type?.toString()) {
            case "select":
                let options = _types?.options;
                if (_keyName === 'manoObraCategoriaDefault') options = categorias.map(opt => ({ label: opt?.NombreCategoria, value: opt?.Categoria }));
                if (_keyName === 'manoObraTipoHoraDefault') options = tipos.map(opt => ({ label: opt?.NombreTipoHora, value: opt?.TipoHora }));
                if (_keyName === 'statusByDefault') options = noticeStatuses.map(opt => ({ label: opt?.description, value: opt?.status }));
                if (_keyName === 'priorityByDefault') options = noticePriorities.map(opt => ({ label: opt?.description, value: opt?.priority }));
                return (
                    <EsfirusSelect
                        options={options}
                        value={state[id]?.[keyName]?.[_keyName]}
                        change={(e) => {
                            if (_types?.multiple) {
                                const value = state[id]?.[keyName]?.[_keyName];
                                var newValue = [];
                                newValue = e === 'All' ? [...options?.filter(e => e.value !== 'All')?.map(opt => opt.value)] : value.find(_e => _e === e) ? [...value?.filter(_e => _e !== e)] : [...value, e]
                                handleChange(newValue, keyName, _keyName)
                            } else {
                                handleChange(e, keyName, _keyName)
                            }
                        }}
                        placeholder=""
                        multiple={_types?.multiple ?? false}
                    >
                    </EsfirusSelect>
                )
            case "text":
            default:
                return (
                    <EsfirusInputTextNative
                        type={_types?.type}
                        value={state[id]?.[keyName]?.[_keyName]}
                        change={(e) =>
                            handleChange(e, keyName, _keyName)
                        }
                    ></EsfirusInputTextNative>
                )
        }
    }

    return (
        <>

            {state[id] ?
                <>
                    {Object.keys(state[id]).map((keyName, i) => (

                        <Grid
                            className="config-container"
                            container
                            spacing={1}
                            alignItems={"left"}
                        >
                            <Grid
                                className="flex-row"
                                item
                                xs={12}
                                md={2}
                                alignItems={"top"}
                                justifyContent={"center"}
                            >
                                <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
                                <h4>{textos[keyName] ?? keyName}</h4>
                            </Grid>
                            <Grid item xs={12} md={10} container spacing={2}>
                                {
                                    Object.keys(state[id]?.[keyName]).map((_keyName, _i) => {
                                        return (
                                            hiddenFields.current?.[_keyName]
                                                ? null
                                                : <Grid item xs={12} md={9}>
                                                    <label>{textos[_keyName] ?? _keyName}</label>
                                                    {
                                                        renderAtribute(keyName, _keyName)
                                                    }
                                                </Grid>
                                        )
                                    })
                                }
                            </Grid>

                        </Grid>


                    ))}


                    {id === "conexion" && state[id]?.ftp_client?.ftp_method === "fichero" &&
                        <Grid
                            className="config-container"
                            style={{ marginBottom: 30 }}
                            container
                            spacing={1}
                            alignItems={"center"}
                        >
                            <Grid
                                className="flex-row"
                                item
                                xs={12}
                                md={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <div className="button-container center">
                                    <EsfirusButton
                                        onClick={forceExport}
                                        fullWidth
                                        color="primary"
                                    >
                                        Forzar envio ficheros
                                    </EsfirusButton>

                                </div>
                            </Grid>
                        </Grid>
                    }

                    {/* Botón para reenviar los fichajes y partes no grabados */}
                    {id === "conexion" && state[id]?.ftp_client?.ftp_method === "endpoint" &&
                        <Grid
                            className="config-container"
                            style={{ marginBottom: 30 }}
                            container
                            spacing={1}
                            alignItems={"center"}
                        >
                            <Grid
                                className="flex-row"
                                item
                                xs={12}
                                md={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <div className="button-container center">
                                    <EsfirusButton
                                        onClick={handleReintentarEnvíoPorEndpoint}
                                        fullWidth
                                        disabled={loadingReenvio}
                                        color="primary"
                                    >
                                        Reintentar envío de fichajes y partes
                                    </EsfirusButton>
                                </div>
                                {loadingReenvio && (
                                    <div style={{ marginLeft: "20px" }}>Procesando...</div>
                                )}
                            </Grid>
                        </Grid>
                    }

                    {/* Botón para refrescar los datos de la caché de la api*/}
                    {id === "conexion" &&
                        <Grid
                            className="config-container"
                            style={{ marginBottom: 30 }}
                            container
                            spacing={1}
                            alignItems={"center"}
                        >
                            <CacheInfo setCache={setCache} cache={cache} setCategorias={setCategorias} setTipos={setTipos} />
                            <Grid
                                item
                                xs={12}
                                md={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <div className="button-container center">
                                    <EsfirusButton
                                        onClick={handleRefreshCacheDataApi}
                                        fullWidth
                                        disabled={loadingRefresh}
                                        color="primary"
                                    >
                                        Refrescar todos los datos del servidor
                                    </EsfirusButton>
                                </div>
                                {loadingRefresh && (
                                    <div style={{ marginLeft: "20px" }}>Refrescando datos ...</div>
                                )}
                            </Grid>
                        </Grid>
                    }

                    <Grid
                        className="line-services-container"
                        container
                        spacing={1}
                        alignItems={"left"}
                    >
                        <Grid
                            className="flex-row"
                            item
                            xs={12}
                            md={5}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <div className="button-container center">
                                <EsfirusButton
                                    onClick={() => {
                                        history(-1)
                                    }}
                                    fullWidth
                                    color="primary"
                                >
                                    Regresar
                                </EsfirusButton>

                            </div>
                        </Grid>

                        <Grid
                            className="flex-row"
                            item
                            xs={12}
                            md={5}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <div className="button-container center">
                                <EsfirusButton
                                    onClick={actualizarConfig}
                                    fullWidth
                                    color="primary"
                                >
                                    Actualizar
                                </EsfirusButton>

                            </div>
                        </Grid>


                    </Grid>
                </>
                :
                <div>
                    No implementado
                </div>
            }

            <ModalConfirm open={openConfirmModal} setOpen={setOpenConfirmModal} msg={msg} />

        </>
    )

}

export { Config };