import moment from "moment"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import ListadoAvisosFilters from "../BBs/ListadoAvisosFilters/ListadoAvisosFilters"
import ListadoAvisosBorradoresTable from "./BBs/ListadoAvisosBorradoresTable/ListadoAvisosBorradoresTable"
import Loader from "@components/ui/Loader/Loader"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable"
import { avisosService } from "@services/avisos"
import { NoticeDraft } from "@models/notices"

const ListadoAvisosBorradores = () => {
  const [filters, setFilters] = useState({
    empresa: "",
    dateFrom: moment().startOf("day").add(-3, "month"),
    dateTo: moment().startOf("day").add(1, "day").add(-1, "second"),
    customer: "",
    obra: "",
    empleadoDestino: "",
    empleadoOrigen: "",
    empleadoResponsable: "",
    status: "",
    type: ""
  })

  const [borradoresAvisos, setBorradoresAvisos] = useState<NoticeDraft[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [loadingBorradoresAvisos, setLoadingBorradoresAvisos] = useState<boolean>(false)
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })

  const fetchAvisosBorradores = async () => {
    setLoadingBorradoresAvisos(true)
    try {
      const resp = await avisosService.getListBorradores({
        from: filters.dateFrom.unix(),
        to: filters.dateTo.unix(),
        company: filters.empresa,
        customer: filters.customer,
        obra: filters.obra,
        originEmployee: filters.empleadoOrigen,
        targetEmployee: filters.empleadoDestino,
        responsibleEmployee: filters.empleadoResponsable,
        status: filters.status,
        type: filters.type,
        pagination: pagination
      })
      const newItems = resp?.data?.data?.items ?? []
      const newTotalItems = resp?.data?.data?.total_items ?? 0

      setBorradoresAvisos(newItems)
      setTotalItems(newTotalItems)
    } catch {
      setBorradoresAvisos([])
      setTotalItems(0)
    } finally {
      setLoadingBorradoresAvisos(false)
    }
  }

  useEffect(() => {
    fetchAvisosBorradores()
  }, [filters, pagination])

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <ListadoAvisosFilters filters={filters} setFilters={setFilters} items={borradoresAvisos} isForBorrador />
      {loadingBorradoresAvisos ? (
        <Loader message="Cargando listado de borradores de avisos" />
      ) : (
        <ListadoAvisosBorradoresTable
          borradoresAvisos={borradoresAvisos}
          pagination={pagination}
          totalItems={totalItems}
          handleChangePagination={handleChangePagination}
          reloadBorradoresAvisos={async () => await fetchAvisosBorradores()}
        />
      )}

      {/* Para cargar el modal de view y edit avisos */}
      <Outlet />
    </>
  );
}

export default ListadoAvisosBorradores;
