import { apiConfig } from "./config";
import { Configuration } from "./swagger/configuration";
import { ConfigsApi } from "./swagger/apis/configs-api";
import {
  configFrontProps,
  configListadoProps,
  configCabeceraParteProps,
  configBuscadorObra,
  configLineasParte,
  configReport,
  configModulos,
  configFichaje,
  configLineasPedido,
  configAvisos
} from "@models/config";

class ConfigApp extends ConfigsApi {
  accessToken = "";

  constructor(_apiConfig: Configuration) {
    super(_apiConfig);
  }

  async getConfigBack() {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    const res = await this.configKeyGet("camposFront", {
      headers: { Authorization: accessToken },
    });

    const configdata: any = res?.data;
    if (configdata) {
      const parsedConfig = JSON.parse(configdata.data.data);
      localStorage.setItem("ef-configuration", JSON.stringify(parsedConfig));
    }

    return res;
  }

  getConfig(): configFrontProps {
    const config = localStorage?.getItem("ef-configuration") || "";
    return JSON.parse(config);
  }

  getConfigListado(): configListadoProps {
    return this.getConfig().listado;
  }

  getConfigCabeceraParte(): configCabeceraParteProps {
    return this.getConfig().cabeceraParte;
  }

  getConfigBuscadorObra(): configBuscadorObra {
    return this.getConfig().buscadorObra;
  }

  getConfigLineasParte(): configLineasParte {
    return this.getConfig().lineasParte;
  }

  getConfigLineasPedido(): configLineasPedido {
    return this.getConfig().lineasPedido;
  }

  getConfigReport(): configReport {
    return this.getConfig().report;
  }

  getConfigFichaje(): configFichaje {
    return this.getConfig().fichaje;
  }

  getConfigAvisos(): configAvisos {
    return this.getConfig().avisos;
  }

  getModulosActivos(): string[] {
    let modulosActivos: string[] = []
    Object.entries(this.getConfig().modulos).forEach(([key, value]) => {
      if (value) modulosActivos.push(key)

    })
    return modulosActivos
  }
}

export const configurationService = new ConfigApp(apiConfig);
