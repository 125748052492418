import { useEffect, useState } from "react"
import { useSelector } from "@services/hooks/redux-hooks"
import moment, { Moment } from "moment"
import { Box, FormControl } from "@mui/material"

import EsfirusButton from "@components/ui/Button/EsfirusButton"

import { Obra } from "@models/obra"

import "./FormParte.scss"
import { getInternalCompany } from "@helpers/companyData"
import HeaderFormAviso from "./BBs/HeaderFormAviso/HeaderFormAviso"
import { erpService } from "@services/erp"
import { CustomerAddress } from "@models/customer-address"
import { NoticePriority, NoticeStatuses, NoticeTypes } from "@models/notices"
import { configurationService } from "@services/configApp"
import { UserWithDataParsed } from "@models/user"
import { Empresa } from "@models/empresa"

export interface FormDataAviso {
  company: Empresa | null
  obra: Obra | void | null
  customerAddress: CustomerAddress | null
  noticeType: NoticeTypes | null
  noticeStatus: NoticeStatuses | null
  noticePriority: NoticePriority | null
  date: Moment
  time: Moment
  responsibleEmployee: UserWithDataParsed | null
  targetEmployee: UserWithDataParsed | null
  originEmployee: UserWithDataParsed | null
  shortDescription: string
  comments: string
  draft_id?: number | string
  unique_id?: string
}

export interface FormAvisoProps {
  editable: boolean
  isSaving?: boolean
  isValidating?: boolean
  initialData?: FormDataAviso | null
  handleSave?: (a: FormDataAviso) => Promise<void>
  handleClose?: () => void
  handleValidate?: (a: FormDataAviso) => Promise<void>
}

const FormAviso = ({ initialData, editable, isSaving, isValidating, handleSave, handleClose, handleValidate }: FormAvisoProps) => {
  const empresas = useSelector(state => state.empresas.list)
  const noticesStatuses = useSelector(state => state.notices.listStatuses)
  const noticesPriorities = useSelector(state => state.notices.listPriorities)

  // Configuración de avisos del backoffice.
  const { statusByDefault, priorityByDefault } = configurationService.getConfigAvisos();

  // Recopilación de toda la data del formulario.
  const [formData, setFormData] = useState<FormDataAviso>(initialData ?? {
    company: empresas.find(em => em.Empresa == getInternalCompany()) ?? null,
    obra: null,
    customerAddress: null,
    noticeType: null,
    noticeStatus: noticesStatuses.find(ns => ns.status == statusByDefault) ?? null,
    noticePriority: noticesPriorities.find(np => np.priority == priorityByDefault) ?? null,
    date: moment(),
    time: moment(),
    responsibleEmployee: null,
    targetEmployee: null,
    originEmployee: null,
    shortDescription: "",
    comments: "",
  })

  // Carga de direcciones de clientes.
  const [loadingCustomerAddresses, setLoadingCustomerAddresses] = useState<boolean>(false)
  const [customerAddressesList, setCustomerAddressesList] = useState<CustomerAddress[]>([])

  // Actualización si cambia el código de cliente.
  useEffect(() => {
    if (!!formData.obra?.Cliente) fetchCustomerAddresses(formData.obra.Cliente)
  }, [formData.obra?.Cliente])

  // Validación para renderizar apartado de líneas o no
  const dataIsOkey =
    formData.company?.Empresa &&
    (formData.obra?.obraCode || formData.obra?.Obra) &&
    formData.customerAddress?.address_id &&
    formData.noticeType?.notification_type &&
    formData.noticeStatus?.status &&
    formData.noticePriority?.priority &&
    formData.date && formData.date.isValid() &&
    formData.time && formData.time.isValid() &&
    formData.responsibleEmployee?.id &&
    formData.targetEmployee?.id &&
    formData.originEmployee?.id &&
    formData.shortDescription &&
    formData.comments

  const fetchCustomerAddresses = async (customerId: number | string) => {
    setLoadingCustomerAddresses(true)

    try {
      const addressesResponse = await erpService.getCustomerAddresses(customerId)
      const addresses = addressesResponse.data.page_entries
      setCustomerAddressesList(addresses)
    } catch (error) {
      setCustomerAddressesList([])
    } finally {
      setLoadingCustomerAddresses(false)
    }
  }

  return (
    <FormControl className="parte-form" fullWidth>
      <HeaderFormAviso
        formData={formData}
        setFormData={setFormData}
        editable={editable}
        customerAddressesData={{ loading: loadingCustomerAddresses, list: customerAddressesList }}
      />
      <Box className="ml-20 mr-20 mt-40 botonera">
        {handleClose && (
          <EsfirusButton
            className="new-parte-save-button"
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
            disabled={isSaving}
          >
            Cerrar
          </EsfirusButton>
        )}
        {handleSave && (
          <EsfirusButton
            className="new-parte-save-button"
            color="primary"
            onClick={async () => await handleSave(formData)}
            disabled={!dataIsOkey || isSaving}
          >
            {isSaving && <span style={{ height: 12, margin: 0 }} className="loader"></span>}
            Guardar
          </EsfirusButton>
        )}
        {handleValidate && (
          <EsfirusButton
            className="new-parte-save-button"
            color="secondary"
            onClick={async () => await handleValidate(formData)}
            disabled={!dataIsOkey || isValidating}
          >
            {isValidating && <span style={{ height: 12, margin: 0 }} className="loader"></span>}
            Traspasar
          </EsfirusButton>
        )}
      </Box>
    </FormControl>
  )
}

export default FormAviso
