import { useEffect, useRef, useState } from "react";
import { cacheService } from "@services/cache"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Splash.scss";
import { setObras } from "@store/slices/obras";
import { setCapitulos } from "@store/slices/capitulos";
import { setcategorias } from "@store/slices/categorias";
import { setempleados } from "@store/slices/empleados";
import { setempresas } from "@store/slices/empresas";
import { setpresupuestos } from "@store/slices/presupuestos";
import { setHoras, setHorasByEmpleado } from "@store/slices/tipoHora";
import { setCalendarios } from "@store/slices/calendarios";
import { setarticulosGastosServicios } from "@store/slices/articulos-gastos-servicios";
import { setReports } from "@store/slices/reports";
import { reportsService } from "@services/reports";
import { configurationService } from "@services/configApp";
import { setConfigurations } from "@store/slices/configs";
import { setManoObra } from "@store/slices/manoObra";
import { isAdmin } from "@services/hooks/UserHook";
import { setProveedores } from "@store/slices/proveedores";
import { setNoticesPriorities, setNoticesStatuses, setNoticesTypes } from "@store/slices/notices";
import { setProductsBarcodes } from "@store/slices/productsBarcodes";
import EsfirusButton from "@components/ui/Button/EsfirusButton";
import { usersService } from "@services/users";
import { safeJsonParse } from "@helpers/utils";
import { UserData } from "@models/user";
import { setUsers } from "@store/slices/users";

function Splash() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reloadCounter, setReloadCounter] = useState<number>(0)
  const [messageError, setMessageError] = useState<string>("")
  const isAdminUser = isAdmin()

  const localConfigs = useRef<any>({} as any);

  useEffect(() => {
    if (!loading) {
      loadStore();
    }
    let navigatePage = localStorage.getItem("ef-navigate") ?? "/home";
    if (navigatePage.includes("backoffice")) {
      window.location.href = window.location.protocol + '//' + window.location.host + navigatePage
      localStorage.removeItem("ef-navigate")

    }
  }, []);

  const loadStore = async () => {
    setLoading(true);

    try {
      const resConfigs = await configurationService.getConfigBack();

      if (resConfigs?.data?.data?.data) {
        const configs = JSON.parse(resConfigs?.data?.data?.data || "{}")
        localConfigs.current = configs
        dispatch(setConfigurations(configs));
      } else {
        setTimeout(loadStore, 3000);
        return
      }

      const modulosActivos = configurationService.getModulosActivos()
      if ((!modulosActivos || !modulosActivos.length) && isAdmin()) {
        window.location.href = window.location.protocol + '//' + window.location.host + "/backoffice"
        return
      }

      // Recuperamos todos los usuarios para poder tenerlos en el store.
      const allUsers = await usersService.getAll()
      const allUsersWithDataParsed = allUsers.data.map(user => ({ ...user, data: safeJsonParse(user.data) as UserData })).filter(user => !!user.data)
      dispatch(setUsers(allUsersWithDataParsed))

      // Resto de datos para cargar.
      const calls = Promise.all([
        reportsService.getLastMonths(),
        cacheService.getCache(),
      ]);
      calls.then(res => {
        res.map((r: any) => r.data).forEach(r => {
          if (r.message === 'Reports') {
            dispatch(setReports(r.data));
            return;
          }

          const modulosActivos = configurationService.getModulosActivos()

          let justFichajes = modulosActivos.length === 1 && modulosActivos[0] === "fichajes"
          const pedidosActivated = modulosActivos.includes("pedidos")

          let checkFields = ["obras", "capitulos", "categorias", "empleados", "empresas", "presupuestos", "tipos", "tarifas", "calendarios", "mano_obra"]
          if (justFichajes) checkFields = ["empleados", "empresas"]
          if (pedidosActivated) checkFields = [...checkFields, "proveedores"]

          const tablasLetEmptyArray = ["capitulos", "presupuestos"]

          let reload = false;
          checkFields.forEach((key) => {
            if (!r[key] || (!tablasLetEmptyArray.includes(key) && !r[key].length)) {
              console.log("reload fail key", key);
              return reload = true;
            }
          })

          const hasLoginErrors = r.login_errors && r.login_errors.length > 0
          if (reload || hasLoginErrors) {
            setReloadCounter(prev => prev + 1)
            setTimeout(loadStore, 3000)
            if (hasLoginErrors) setMessageError(r.login_errors[0])
            else setMessageError("Alguna instancia de graphQL no se ha cargado correctamente.")
            return
          }

          if (!justFichajes) {

            dispatch(setObras(r.obras));
            dispatch(setCapitulos(r.capitulos));
            dispatch(setcategorias(r.categorias));
            dispatch(setpresupuestos(r.presupuestos));
            dispatch(setHoras(r.tipos));
            dispatch(setHorasByEmpleado(r.tipos_hora_empleado));
            dispatch(setarticulosGastosServicios(r.tarifas));
            dispatch(setCalendarios(r.calendarios));
            dispatch(setManoObra(r.mano_obra));
          }
          dispatch(setempleados(r.empleados));
          dispatch(setempresas(r.empresas));
          dispatch(setProveedores(r.proveedores));
          dispatch(setNoticesPriorities(r.notices_priorities));
          dispatch(setNoticesStatuses(r.notices_statuses));
          dispatch(setNoticesTypes(r.notices_types));
          dispatch(setProductsBarcodes(r.products_barcodes));

          setLoading(false);
          let navigatePage = localStorage.getItem("ef-navigate") ?? "/home";
          localStorage.removeItem("ef-navigate")
          navigate(navigatePage);
        });
      })
    } catch (error) {
      console.error("Error loading store", error);
    }
  }

  const handleNavigateToBackoffice = () => {
    const url = window.location.origin + "/backoffice"
    window.location.href = url
  }

  return (
    <>
      {loading && (
        <div className="backdrop">
          <span className="loader"></span>
          <span>Cargando ...</span>
          {messageError !== "" && <p className="text-center mt-40 error">{messageError}</p>}
          {isAdminUser && reloadCounter >= 3 && (
            <div className="navigate-backoffice-splash-button">
              <EsfirusButton variant="contained" onClick={handleNavigateToBackoffice}>Acceder al backoffice</EsfirusButton>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Splash;
