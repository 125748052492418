import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment"

import EsfirusTable from "@components/ui/Table/EsfirusTable"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";
import { Pedido } from "@models/pedidos";
import { findUserByCompanyAndEmployeeIds } from "@helpers/utils";

interface ListadoPedidosTableProps {
  pedidos: Pedido[]
  pagination: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination: HandleChangePaginationType
}

const ListadoPedidosTable = ({ pedidos, pagination, totalItems, handleChangePagination }: ListadoPedidosTableProps) => {
  const navigate = useNavigate()
  const empresas = useSelector((state) => (state as any).empresas).list;
  const proveedores = useSelector((state) => (state as any).proveedores).list;
  const users = useSelector((state) => (state as any).users).list;

  const columns = [
    {
      name: "Empresa - Nº Pedido",
      keymap: "_id",
      see: true,
      order: true,
      width: 150,
      handleView: (row: any) => navigate(`/pedidos/${row.unique_id}`),
      // No permitimos editar el pedido de momento hasta nuevo aviso. Comment de Jira: https://esfirus.atlassian.net/browse/PARTRAB-295?focusedCommentId=32303.
      // Permitimos editar, pero solo los adjuntos. https://esfirus.atlassian.net/browse/PARTRAB-566?focusedCommentId=33321
      handleEdit: (row: any) => navigate(`/pedidos/${row.unique_id}/edit`)
    },
    {
      name: "Fecha",
      keymap: "order_date",
      parse: (v: any) => moment(v).format("DD-MM-YYYY"),
      order: true,
      width: 50
    },
    {
      name: "Nº y Nombre de proveedor",
      keymap: "supplier_info",
      order: true,
      width: 150
    },
    {
      name: "Referencia (Empleado)",
      keymap: "reference_employee",
      order: true,
      width: 100
    },
    {
      name: "Estado",
      keymap: "status_r_606",
      order: true,
      width: 100
    },
  ]

  const parseRows = (rows: Pedido[] = []) => {
    return rows.map(row => {
      const empresaName = empresas.find((empresa: any) => empresa.Empresa == row.company_r_403)?.Nombre ?? ""
      const supplierName = proveedores.find((proveedor: any) => proveedor.Proveedor == row.supplier_code)?.RazonSocial ?? ""
      const empleadoOrigenName = findUserByCompanyAndEmployeeIds(users, row.company_r_403, row.reference)
      return ({
        ...row,
        _id: `${empresaName} - ${row.order_number}`,
        supplier_info: `${row.supplier_code} - ${supplierName}`,
        reference_employee: `${row.reference} ${empleadoOrigenName ? "-" : ""} ${empleadoOrigenName?.nombre ?? ""} ${empleadoOrigenName?.apellidos ?? ""}`
      })
    })
  }

  return (
    <EsfirusTable
      columns={columns}
      rows={parseRows(pedidos)}
      rowsCount={totalItems}
      pagination={pagination}
      withPagination={true}
      handleChangePagination={handleChangePagination}
    />
  )
}

export default ListadoPedidosTable